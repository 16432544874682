import {createFilteredContactFetcher} from "../contact-list/filtered-contact-fetcher";
import {createContactFetcher} from "../contact-list/contact-fetcher";
import {createContactFilterGroup} from "../contact-list/contact-filter-group";
import {createContactList} from "../contact-list/contact-list";
import {debounceFlowByEventCycles} from "../contact-list/flow-batch-by-event-cycles";
import { onDomReady } from "../../on-dom-ready";

const createLowerSectionController = (element: HTMLElement) => {
  return {
    show: () => element.hidden = false,
    hide: () => element.hidden = true
  } as const
}

const positionLoadingIndicator = (root: HTMLElement, loadingIndicator: HTMLElement | null) => {
  if (!loadingIndicator) {
    return
  }

  const minDistance = 20 // from root top in px

  const absoluteScreenMiddle = document.documentElement.scrollTop + window.innerHeight / 2

  const rootTop = root.offsetTop
  const rootHeight = root.clientHeight
  const loadingIndicatorHeight = parseFloat(getComputedStyle(loadingIndicator).height)

  if (rootHeight < minDistance * 2 + loadingIndicatorHeight) {
    loadingIndicator.style.top = (rootHeight / 2 - loadingIndicatorHeight / 2) + "px"
    return
  }

  if (absoluteScreenMiddle - loadingIndicatorHeight / 2 < rootTop + minDistance) {
    loadingIndicator.style.top = minDistance + "px"
    return
  }

  if (absoluteScreenMiddle + loadingIndicatorHeight / 2 > rootTop + rootHeight - minDistance) {
    loadingIndicator.style.top = (rootHeight - minDistance - loadingIndicatorHeight) + "px"
    return
  }

  loadingIndicator.style.top = (absoluteScreenMiddle - rootTop - loadingIndicatorHeight / 2) + "px"
}

/**
 * Main part of the "All Contacts" plugin which contains two contact lists:
 * - an upper list that holds general contacts
 * - a lower list that holds more specific contacts with detailed filters.
 *
 * The interaction between the two is rather simple.
 *
 * For the upper list, the underlying form of the fetcher is used,
 * to completely implement the static filter / request enhancer.
 * We only catch form submits and convert them into forced updates.
 * Since the fetcher always uses the actual form values as a base,
 * the latest fetch after a forced update will always contain the
 * current form values.
 *
 * For the lower list, the normal filter group is used which is already capable
 * of handling dynamic filters. Since the same fetcher is used here
 * the static filter / request enhancer is automatically applied.
 *
 * Depending on whether the user has selected a dynamic filter,
 * the upper (general contact) list is updated and the lower one clear or
 * the upper one is keep and the lower one is updated.
 *
 * The random content in between is just unveil on any interaction.
 */
const initializeDualFilterableContactList = (root: HTMLElement) => {
  const emptyOption = root.dataset["emptyOption"]

  const fetchForm = root.querySelector<HTMLFormElement>('form.filterable-contact-list__fetch-form')!!
  const loadingIndicator = root.querySelector<HTMLFormElement>('.filterable-contact-list__loading-overlay')!!
  const lowerSectionController = createLowerSectionController(
    root.querySelector<HTMLElement>('.filterable-contact-list--lower')!!
  )
  document.addEventListener("scroll", () => {
    positionLoadingIndicator(root, loadingIndicator.querySelector<HTMLElement>('img'))
  })

  const filteredFetch = createFilteredContactFetcher(
    createContactFetcher(
      fetchForm,
      "all"
    ),
    createContactFilterGroup(
      [],
      root.querySelector<HTMLElement>('.filterable-contact-list__filter-container--lower')!!,
      loadingIndicator,
      emptyOption
    ),
    loadingIndicator
  );

  fetchForm.addEventListener('submit', (e) => {
    e.preventDefault()
    loadingIndicator.classList.add("filterable-contact-list__loading-overlay--visible")
    filteredFetch.forceUpdate()
  })

  const upperList = createContactList(
    root.querySelector('.filterable-contact-list__contacts-container--upper')!!,
    "general"
  )
  upperList.update([])

  const lowerList = createContactList(
    root.querySelector('.filterable-contact-list__contacts-container--lower')!!,
    "specific"
  )
  lowerList.update([])

  filteredFetch.updates
    .take(async () => {
      loadingIndicator.classList.remove("filterable-contact-list__loading-overlay--visible")
    })
    .with(debounceFlowByEventCycles)
    .each(async ({userInputs, response}) => {
      lowerSectionController.show()
      if (userInputs.length) {
        lowerList.update(response.contacts ?? [], response.message)
      } else {
        upperList.update(response.contacts ?? [])
        lowerList.update(response.contacts ?? [], response.message)
      }
    })
}

onDomReady(() => {
  for (const rootElement of document.getElementsByClassName('filterable-contact-list--dual')) {
    if (!(rootElement instanceof HTMLElement)) {
      continue;
    }
    try {
      initializeDualFilterableContactList(rootElement);
    } catch (e: unknown) {
      console.error("Failed to initialize dual filterable contact list. Hiding element cause I feel ashamed. ", e)
      rootElement.hidden = true
    }
  }
});
